// TODO: comeback here
var fontsData = {
  rome: {
    "font-family": "RomeBold-Regular",
    "font-weight": "400",
    "stroke-width": "0.7px",
    "letter-spacing": "1.70px",
    "text-transform": "uppercase",
    "font-size": "28px",
    "font-size-heading": "62px",
    "font-line-height": "65px",
  },
  avenir: {
    "font-family": "Avenir-Regular",
    "font-weight": "400",
    "stroke-width": "0.9px",
    "letter-spacing": "1.68px",
    "text-transform": "uppercase",
    "font-size": "32px",
    "font-size-heading": "58px",
    "font-line-height": "65px",
  },
  bmikon: {
    "font-family": "BMIkon-Thickened",
    "font-weight": "400",
    "stroke-width": "0.7px",
    "letter-spacing": "1.68px",
    "text-transform": "uppercase",
    "font-size-heading": "68px",
    "font-size": "32px",
    "font-line-height": "65px",
  },
  london: {
    "font-family": "London-Regular",
    "font-weight": "400",
    "stroke-width": "0.7px",
    "letter-spacing": "1.68px",
    "text-transform": "uppercase",
    "font-size": "27px",
    "font-size-heading": "48px",
    "font-line-height": "65px",
  },
  cormorant: {
    "font-family": "Cormorant",
    "font-weight": "400",
    "stroke-width": "0.7px",
    "letter-spacing": "1.68px",
    "text-transform": "none",
    "font-size": "32px",
    "font-size-heading": "68px",
    "font-line-height": "65px",
  },
  tangerine: {
    "font-family": "Tangerine",
    "font-weight": "400",
    "stroke-width": "0.7px",
    "letter-spacing": "1.68px",
    "text-transform": "uppercase",
    "font-size": "32px",
    "font-size-heading": "68px",
    "font-line-height": "65px",
  },
  clementine: {
    "font-family": "Tangerine",
    "font-weight": "400",
    "stroke-width": "0.5px",
    "letter-spacing": "1.0px",
    "text-transform": "none",
    "font-size": "32px",
    "font-size-heading": "68px",
    "font-line-height": "65px",
  },
  granola: {
    "font-family": "granola-font",
    "font-weight": "400",
    "stroke-width": "0.7px",
    "letter-spacing": "1.68px",
    "text-transform": "uppercase",
    "font-size": "32px",
    "font-size-heading": "68px",
    "font-line-height": "65px",
  },
  "brown-sugar": {
    "font-family": "Brown Sugar",
    "font-weight": "400",
    "stroke-width": "0.7px",
    "letter-spacing": "1.68px",
    "text-transform": "uppercase",
    "font-size": "32px",
    "font-size-heading": "68px",
    "font-line-height": "65px",
  },
  santorini: {
    "font-family": "Santorini",
    "font-weight": "400",
    "stroke-width": "0.2px",
    "letter-spacing": "0px",
    "text-transform": "none",
    "font-size": "23px",
    "font-size-heading": "42px",
    "font-line-height": "65px",
    "font-mobile-padding": "20px",
  },
  "open-sans": {
    "font-family": "Open Sans",
    "font-weight": "600",
    "stroke-width": "0px",
    "letter-spacing": "-2px",
    "text-transform": "none",
    "font-size": "32px",
    "font-size-heading": "48px",
    "font-line-height": "54px",
  },
};

var themesData = {
  "thank-you-five": {
    "theme-main": "#ADB7AC",
    "theme-main-hover": "#BBC8BB",
    "theme-main-press": "#A2A9A2",
    "theme-light": "#E3E7E3",
    "theme-light-rgb": "227, 231, 227",
    "theme-text": "#0F1D13",
  },
  jolene: {
    "theme-main": "#B57236",
    "theme-main-hover": "#d9aa80",
    "theme-main-press": "#edd6c2",
    "theme-light": "#e6c6aa",
    "theme-light-rgb": "230, 198, 170",
    "theme-text": "#0F1D13",
  },
  spotlight: {
    "theme-main": "#B48F4A",
    "theme-main-hover": "#d2bc92",
    "theme-main-press": "#e9ddc9",
    "theme-light": "#e1d2b6",
    "theme-light-rgb": "225, 210, 182",
    "theme-text": "#0F1D13",
  },
  "tarzan-boy": {
    "theme-main": "#786D48",
    "theme-main-hover": "#b7ad88",
    "theme-main-press": "#dbd6c3",
    "theme-light": "#cfc8af",
    "theme-light-rgb": "207, 200, 175",
    "theme-text": "#fff",
  },
  "emerald-city": {
    "theme-main": "#2D4337",
    "theme-main-hover": "#709f85",
    "theme-main-press": "#b8cfc2",
    "theme-light": "#a0bfae",
    "theme-light-rgb": "160, 191, 174",
    "theme-text": "#FFF",
  },
  "sage-the-gemini": {
    "theme-main": "#89967d",
    "theme-main-hover": "#b8c0b1",
    "theme-main-press": "#dcdfd8",
    "theme-light": "#d0d5cb",
    "theme-light-rgb": "208, 213, 203",
    "theme-text": "#FFF",
  },
  "azul-carter-knowles": {
    "theme-main": "#2B464F",
    "theme-main-hover": "#689cad",
    "theme-main-press": "#b4cdd6",
    "theme-light": "#9abdc8",
    "theme-light-rgb": "154, 189, 200",
    "theme-text": "#FFF",
  },
  "hi-barbie": {
    "theme-main": "#CE3468",
    "theme-main-hover": "#e285a4",
    "theme-main-press": "#f0c2d2",
    "theme-light": "#ebaec3",
    "theme-light-rgb": "235, 174, 195",
    "theme-text": "#FFF",
  },
  "thank-u-next": {
    "theme-main": "#C6B5C9",
    "theme-main-hover": "#eee9ef",
    "theme-main-press": "#ddd3df",
    "theme-light": "#e8e1e9",
    "theme-light-rgb": "232, 225, 233",
    "theme-text": "#0F1D13",
  },
  "orinoco-flow": {
    "theme-main": "#93B6EE",
    "theme-main-hover": "#bed3f5",
    "theme-main-press": "#dfe9fa",
    "theme-light": "#d4e2f8",
    "theme-light-rgb": "212, 226, 248",
    "theme-text": "#0F1D13",
  },
  "solo-jazz": {
    "theme-main": "#229DA5",
    "theme-main-hover": "#64d8df",
    "theme-main-press": "#b2ebef",
    "theme-light": "#98e5ea",
    "theme-light-rgb": "152, 229, 234",
    "theme-text": "#0F1D13",
  },
  "cowboy-take-me-away": {
    "theme-main": "#9D8A75",
    "theme-main-hover": "#c4b9ac",
    "theme-main-press": "#e2dcd6",
    "theme-light": "#d8d0c8",
    "theme-light-rgb": "216, 208, 200",
    "theme-text": "#0F1D13",
  },
  "critical-edition": {
    "theme-main": "#D9CFC1",
    "theme-main-hover": "#e9e3da",
    "theme-main-press": "#f4f1ec",
    "theme-light": "#f0ece6",
    "theme-light-rgb": "240, 236, 230",
    "theme-text": "#0F1D13",
  },
  "tango-roxanne": {
    "theme-main": "#712130",
    "theme-main-hover": "#ce566c",
    "theme-main-press": "#e6aab6",
    "theme-light": "#de8e9d",
    "theme-light-rgb": "222, 142, 157",
    "theme-text": "#FFFFFF",
  },
  "center-stage": {
    "theme-main": "#C6B1A6",
    "theme-main-hover": "#f4efed",
    "theme-main-press": "##ddd0ca",
    "theme-light": "#e8e0db",
    "theme-light-rgb": "232, 224, 219",
    "theme-text": "#1F1F1F",
  },
  "dani-california": {
    "theme-main": "#9DB3BF",
    "theme-main-hover": "#e2e8ec",
    "theme-main-press": "#ebf0f2",
    "theme-light": "#d8e1e5",
    "theme-light-rgb": "216, 225, 229",
    "theme-text": "#1F1F1F",
  },
  "and-scene": {
    "theme-main": "#1F1F1F",
    "theme-main-hover": "#444444",
    "theme-main-press": "#000000",
    "theme-light": "#6B7070",
    "theme-light-rgb": "237, 244, 244",
    "theme-text": "#FFFFFF",
  },
};
//dark black is original and-scene colors. current and-scene is light-grey theme
export function changeTheme(newTheme) {
  $.each(themesData[newTheme], function (key, value) {
    document.documentElement.style.setProperty("--" + key, value);
  });
}

export function changeFont(newFont) {
  $.each(fontsData[newFont], function (key, value) {
    document.documentElement.style.setProperty("--" + key, value);
  });
}

window.changeTheme = changeTheme;
window.changeFont = changeFont;
